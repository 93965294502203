






































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LivePlayer from "@liveqing/liveplayer";
import { firmTopVideo, bankTopVideo, insuranceTopVideo } from "@/api/index";
import EZUIKit from "ezuikit-js";
import { bigScreen } from "@/common/img_config/index.ts";
import { getBigScreenGovernmentysVideoList } from "@/api/index";
import { BasicsClass } from "@/common/BasicsClass";
@Component({
    components: {
        LivePlayer,
    },
})
export default class BigScreenVideoListVue extends Vue {
    @Prop() VideoListData: any;
    @Prop() videoSource: any;
    @Prop() isYsvideo: any;
    @Prop() showYSVideoFlag: any;
    private autoplay: boolean = true; // 控制播放器是否自动播放
    private showControl: boolean = false; // 控制是否显示 视频控制条
    private token = "";
    private latestRequestId: number = 0; // 用于标记最新的请求
    private player1: any = "";
    private player2: any = "";
    private player3: any = "";
    private player4: any = "";
    private page: any = {
        pageSize: 1,
        pageNo: 1,
    };
    mounted() {
        console.log("VideoListData566+54654");
    }
    private showTypeType: any = false;
    @Watch("VideoListData", { deep: true, immediate: false })
    private changeShowYsVideo() {
        console.log(this.showYSVideoFlag, "VideoListData566+54654");
        console.log(
            this.showYSVideoFlag && !this.showTypeType,
            "this.showYSVideoFlag && !this.showTypeType"
        );

        if (!this.showYSVideoFlag) {
            console.log("清除clearVideoListDataBox");
            // this.clearVideoListDataBox();
            this.clearPlay(); // 清除之前的实例
        }
        this.$nextTick(() => {
            console.log(
                this.showYSVideoFlag && !this.showTypeType,
                "this.showYSVideoFlag && !this.showTypeType789"
            );
            if (this.showYSVideoFlag) {
                this.showTypeType = true;
                console.log(this.VideoListData, "VideoListData");
                console.log(
                    this.VideoListData.data[0],
                    "VideoListData.data[0]"
                );
                console.log(
                    this.VideoListData.data[0].deviceCode,
                    "VideoListData.data[0].deviceCode"
                );
                console.log(this.latestRequestId, "this.latestRequestId666");
                if (
                    this.VideoListData.data[0].deviceCode ==
                    this.latestRequestId
                ) {
                    console.log(
                        this.VideoListData.data[0].deviceCode ==
                            this.latestRequestId,
                        "return了了了"
                    );
                    this.showTypeType = false;
                    return;
                }
                console.log(
                    this.VideoListData.data[0].deviceCode ==
                        this.latestRequestId,
                    "进来了"
                );
                this.clearPlay(); // 清除之前的实例
                console.log(
                    this.VideoListData.data[0].deviceCode ==
                        this.latestRequestId,
                    "进来了1"
                );
                
                this.latestRequestId = this.VideoListData.data[0].deviceCode;
                this.getToken();
                console.log(
                    this.VideoListData.data[0].deviceCode ==
                        this.latestRequestId,
                    "进来了2"
                );
                this.VideoListData.data.forEach((item: any, index: any) => {
                    console.log(
                        this.VideoListData.data[0].deviceCode ==
                            this.latestRequestId,
                        "进来了3"
                    );
                    const containerId = "video-container-" + (index + 1);
                    getBigScreenGovernmentysVideoList(
                        { deviceCode: item.deviceCode },
                        this.page,
                        (data: any) => {
                            const playerElement = document.getElementById(
                                containerId
                            );
                            this.showTypeType = false;
                            if (playerElement) {
                                // 如果已有实例，先销毁旧实例
                                if ((this as any)["player" + (index + 1)]) {
                                    (this as any)[
                                        "player" + (index + 1)
                                    ].stop();
                                }

                                // 创建新的播放器实例
                                (this as any)[
                                    "player" + (index + 1)
                                ] = new EZUIKit.EZUIKitPlayer({
                                    id: containerId,
                                    accessToken: this.tokenKey,
                                    url: item.url,
                                });
                            }
                        },
                        100
                    ); // 延迟 100 毫秒执行
                });
            }
        });
    }
    private tokenKey = "";
    private getToken() {
        console.log(this.VideoListData.data[0].configId, "configId");
        this.showTypeType = false;
        if (this.VideoListData.data[0].configId) {
            new BasicsClass().BasicGet(
                "/animal/web/thirdMonitorChack/getToken",
                { monitorConfigId: this.VideoListData.data[0].configId },
                false,
                true,
                (res: any) => {
                    this.tokenKey = res.data;
                }
            );
        }
    }
    // 清除所有播放器实例的辅助方法
    private clearPlay() {
        console.log(this.VideoListData.data, "this.VideoListData.data");
        if (this.VideoListData.data) {
            this.VideoListData.data.forEach((item: any, index: any) => {
                const playerKey = "player" + (index + 1);
                const playerInstance = (this as any)[playerKey];
                console.log(playerInstance, "playerInstance654654");
                if (playerInstance) {
                    // playerInstance.stop(); // 停止播放
                    delete (this as any)[playerKey]; // 删除实例引用
                }

                const containerId = "video-container-" + (index + 1);
                
                const containerElement = document.getElementById(containerId);
                console.log(containerElement, "containerElement654654");
                if (containerElement) {
                    containerElement.innerHTML = ""; // 清空容器
                }
            });
        }
    }
    private clearVideoListDataBox() {
        let VideoListDataBox = document.getElementById("VideoListDataBox");
        if (VideoListDataBox) {
            VideoListDataBox.innerHTML = "";
        }
    }
    private bigScreen = bigScreen;

    private ShowBigVideo(item: any) {
        this.$emit("ShowBigScreenVideo", item);
    }

    //event 置顶当前视频
    private topThisVideo(item: any) {
        console.log(item);
        //发送置顶请求
        switch (this.videoSource) {
            case "firm":
                firmTopVideo(item.deviceCode, true, () => {});
                break;
            case "bank":
                bankTopVideo(item.deviceCode, true, () => {});
                break;
            case "insurance":
                insuranceTopVideo(item.deviceCode, true, () => {});
                break;
        }
        // topVideo(item.deviceCode, true, () => {});
    }

    // func 提示
    private showTipFunc(flag: boolean) {
        if (flag) {
            this.$message.success(
                this.$t("common.bigScreen.video.copysuccess") as string
            );
        } else {
            this.$message.error(
                this.$t("common.bigScreen.video.copyfail") as string
            );
        }
    }

    // func navigator promise 格式的 复制api
    private copyNavigator(text: string) {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                this.showTipFunc(true);
            })
            .catch((err) => {
                this.showTipFunc(false);
            });
    }

    // func execCommand 复制api
    private copyExecCommand(text: string) {
        // 创建一个临时的textarea元素
        const textarea = document.createElement("textarea");
        textarea.value = text;

        // 隐藏textarea元素
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        document.body.appendChild(textarea);

        // 选择textarea中的文本
        textarea.select();

        try {
            const result = document.execCommand("copy");
            if (result) {
                this.showTipFunc(true);
            } else {
                this.showTipFunc(false);
            }
        } catch (err) {
            this.showTipFunc(false);
        }

        // 移除textarea元素
        document.body.removeChild(textarea);
    }

    // event 复制文字
    private copyText(text: string) {
        if (navigator.clipboard) {
            this.copyNavigator(text);
        } else {
            this.copyExecCommand(text);
        }
    }
}
